import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Flex, Heading, Link, Text } from 'rebass'

import { Header, NavBar } from '../components'
import Layout from '../layouts/default'

const BlogPage = ({data}) => {
  const { edges: posts } = data.allMarkdownRemark; 
  return (
  <Layout>
    <NavBar />
    <Header height='40vh' image='/assets/img/banners/macbook-desk-1200x900.jpg'>
      <Heading is='h1' fontSize={[4, 5, 6]} fontWeight='300' my={3}>Blog</Heading>
    </Header>
    <Container color='dark-gray' m={2}>
      {posts
        .filter(post => post.node.frontmatter.title.length > 0)
        .map(({ node: post }) => {
          return (
            <Flex>
              <Box width={1/4}><Text my={2} fontSize={[0, 1]} fontWeight='300'>{post.frontmatter.date}</Text></Box>
              <Box width={3/4}>
                <Link href={post.fields.path}>
                  <Text my={2} fontSize={[0, 1]}>{post.frontmatter.title}</Text>
                </Link>
              </Box>
            </Flex>
          )
        })}

    </Container>
  </Layout>
)}

export default BlogPage

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark (
      filter: { frontmatter: { slug: { ne: "dummy" } } },
      sort: { order: DESC, fields: [frontmatter___date] }
    ){
      edges {
        node {
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            path
          }
        }
      }
    }
  }
`